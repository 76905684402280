import React from 'react'
import { Redirect } from "react-router-dom";

import Integration from "../pages/Integration/index";

const routes = [
  //routes without Layout
{ path: "/index", component: Integration },
{
  path: "/",
  exact: true,
  component: () => <Redirect to="/index" />,
},
];

export default routes;
