// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//Import Components

//Import Images
import appImg from "../../assets/images/app/app.png";
import playstoreImg from "../../assets/images/app/playstore.png";

class Testi extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section pt-0">
          <Container>
            <Row className="mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">Get the App now !</h4>
                  <p className="text-muted para-desc mx-auto">
                    Start collaborating on Kubo, your all-in-one solution for generating awareness, driving traffic, and fostering meaningful connections.
                  </p>
                  <div className="mt-4">
                    <Link to="#">
                      <img src={appImg} className="m-1" height="50" alt="" />
                    </Link>{" "}
                    <Link to="#">
                      <img src={playstoreImg} className="m-1" height="50" alt="" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Testi;
