import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import FeatherIcon

// Modal Video
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// import image
import { Link } from "react-router-dom";
import onlineImg1 from '../../assets/images/app/app_screen.png';
import onlineImg2 from '../../assets/images/app/app_screen_1.png';
import onlineImg3 from '../../assets/images/app/app_screen_2.png';

const WhatWeDo = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={5} md={6}>
                            <Row className="align-items-center">
                                <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                    <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                        <CardBody className="p-0">
                                            <img src={onlineImg1} className="img-fluid" alt="" />
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <Link to="#" className="title text-white d-block fw-bold">My Circles</Link>
                                                <small className="text-light">View your circles</small>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={6} xs={6}>
                                    <Row>
                                        <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                            <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                                <CardBody className="p-0">
                                                    <img src={onlineImg2} className="img-fluid" alt="" />
                                                    <div className="overlay-work bg-dark"></div>
                                                    <div className="content">
                                                        <Link to="#" className="title text-white d-block fw-bold">Circle  Details</Link>
                                                        <small className="text-light">See circle progress</small>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col lg={12} md={12} className="mt-4 pt-2">
                                            <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                                <CardBody className="p-0">
                                                    <img src={onlineImg3} className="img-fluid" alt="" />
                                                    <div className="overlay-work bg-dark"></div>
                                                    <div className="content">
                                                        <Link to="#" className="title text-white d-block fw-bold">Join Circle</Link>
                                                        <small className="text-light">Support a friend</small>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                            <div className="ms-lg-4">
                                <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4">About Kubo</h4>
                                <p className="text-muted para-desc">
                                    Welcome to <span className="text-primary fw-bold">Kubo</span>, where meaningful connections meet effortless fund collaboration. At Kubo, we believe in the power of shared experiences, and that's why we've created a platform that makes pooling funds with friends not only easy but also fun.
                                </p>
                                <h6>Our mission</h6>
                                <p className="text-muted para-desc mb-0">
                                    Enabling individuals to seamlessly come together, Kubo aims to transform the way you manage and collect funds for shared goals, whether it's planning a road trip, supporting a community member, or celebrating life's special moments.
                                </p>
                                </div>

                                <h6>Why Kubo</h6>
                                <ul className="list-unstyled text-muted">
                                <li className="mb-0">
                                    <span className="text-primary h4 me-2">
                                    <i className="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Simplicity
                                    <p className="text-muted para-desc mb-0">
                                    Creating circles and pooling funds is as easy as a few taps, bringing your shared goals to life.
                                    </p>
                                </li>
                                <li className="mb-0">
                                    <span className="text-primary h4 me-2">
                                    <i className="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Versatility
                                    <p className="text-muted para-desc mb-0">
                                    From travel adventures to community support, Kubo is your all-in-one platform for collaborative fund management.
                                    </p>
                                </li>
                                <li className="mb-0">
                                    <span className="text-primary h4 me-2">
                                    <i className="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Connection
                                    <p className="text-muted para-desc mb-0">
                                    Strengthen bonds with friends and loved ones by contributing to shared experiences and achieving financial goals together.
                                    </p>
                                </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </React.Fragment>
  );
};
export default WhatWeDo;
