// React Basic and Bootstrap
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";

// import images
import appImg from "../../assets/images/app/app.png";
import homeImg from "../../assets/images/app/home.png";
import playstoreImg from "../../assets/images/app/playstore.png";

//Import Components
import Popup from "../../components/Layout/popup";
import Section from "./Section";
import Testi from "./Testi";
import WorkProcess from "./WorkProcess";

const IndexClassicApp = () => {

  useEffect(() => {
    document.body.classList = "";
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
      }
    }
  };
  
  return (
    <React.Fragment>
      <section
        className="d-table w-100 overflow-hidden"
        id="home"
      >
        <Container>
          <Row className="align-items-center">
            <Col lg="7" md="7">
              <div className="title-heading mt-4">
                <Alert
                  color="none"
                  className="alert-transparent alert-pills shadow"
                  role="alert"
                >
                  <span className="badge rounded-pill bg-primary me-2">New</span>
                  <span className="content">
                    Welcome to Kubo 🎉
                  </span>
                </Alert>
                <h1 className="heading mb-3">
                Streamlined fund collection<br/> at your fingertips
                </h1>
                <p className="para-desc text-muted">
                  From group getaways to shared costs. Invite friends effortlessly, contribute to your circles through diverse payment channels.
                </p>
                <div className="mt-4">
                  <Link to="#">
                    <img src={appImg} className="m-1 me-2" height="50" alt="" />
                  </Link>

                  <Link to="#">
                    <img src={playstoreImg} className="m-1" height="50" alt="" />
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg="5" md="5" className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="classic-app-image position-relative">
                <div className="bg-app-shape position-relative">
                  <img
                    src={homeImg}
                    className="img-fluid mover mx-auto d-block"
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>        
      </section>

      {/* render Section */}

      <section className="py-4 bg-light">
        <Container>
          <Row className="justify-content-center">
            
          </Row>
        </Container>
      </section>

      <Section />
      <WorkProcess />

      <Testi />
      <div className="position-relative">
        <div className="shape overflow-hidden text-footer">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <Popup/>
    </React.Fragment>
  );
}

export default IndexClassicApp;
