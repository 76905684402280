import React from "react";

//Import Components
import WhatWeDo from "./WhatWeDo";

const Section = () => {  
    return (
      <React.Fragment>
        <section className="section overflow-hidden">
          <WhatWeDo />
        </section>
      </React.Fragment>
    );  
}

export default Section;
