import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const WorkProcess = () => {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h6 className="text-primary">Kubo Process</h6>
                <h4 className="title mb-4">How It Works</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                Start your financial journey on Kubo by creating circles for shared goals. Invite friends with ease, and together, seamlessly pool funds to bring your collective dreams to life.
                </p>  
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Create Circles:</h5>
                  <p className="text-muted mb-0">
                    Start a circle for any shared goal or expense.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Invite Friends</h5>
                  <p className="text-muted mb-0">
                    Easily invite friends using their phone numbers to join your circle.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Contribute</h5>
                  <p className="text-muted mb-0">
                  Seamlessly pool funds and achieve your shared objectives as a team.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );  
};
export default WorkProcess;
